<template>
  <div class="drop-down w-full">
    <button
      class="drop-down-button"
      :class="{ 'no-text': isText }"
      @click.prevent="isMenuOpen = !isMenuOpen"
    >
      <Icon v-if="icon" :name="icon" height="40px" width="40px" stroke="var(--color-black)" />
      <span v-if="value?.label" class="pr-4 text-left text-sm font-semibold">
        {{ value.label }}
      </span>
      <span v-else class="placeholder">
        {{ placeholder ?? 'Trier par' }}
      </span>

      <div v-if="isText && optionsTypes.length === 0">{{ currentOption?.label }}</div>
      <div v-else-if="isText && optionsTypes.length > 0" class="dropdown-title">
        <span v-if="currentOption?.type !== 'default'" class="semi-bold">{{
          currentOption?.type
        }}</span>
        {{ currentOption?.label }}
      </div>
      <Icon
        v-if="!icon"
        name="arrow-dropdown"
        height="var(--d-icon-size)"
        width="var(--d-icon-size)"
        stroke="var(--color-black)"
      />
    </button>
    <div v-if="isMenuOpen && optionsTypes.length === 0" class="drop-down-menu">
      <div
        v-for="(option, i) in optionsExcluded"
        :key="option.label"
        class="drop-down-item"
        :class="{
          'radius-bottom': i === optionsExcluded.length - 1,
          'radius-top': i === 0
        }"
        @click.prevent="
          currentOption = option;
          isMenuOpen = false;
          emit('update:value', option);
        "
      >
        <div class="text">
          {{ option.label }}
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen && optionsTypes.length > 0" class="drop-down-menu">
      <div
        v-for="(optionType, optionTypeIndex) in optionsTypes"
        :key="optionType.type"
        class="option-type-wrapper"
      >
        <div class="options">
          <div
            v-for="(option, i) in optionType.options"
            :key="option.label"
            class="drop-down-item"
            :class="{
              'radius-bottom': i === optionsExcluded.length - 1,
              'radius-top': i === 0
            }"
            @click="
              currentOption = option;
              isMenuOpen = false;
              emit('update:value', option);
            "
          >
            <div v-if="option.type !== 'default'" class="type">
              {{ option.type }}
            </div>
            <div class="text">
              {{ option.label }}
            </div>
          </div>
          <div v-if="!(optionTypeIndex === optionsTypes.length - 1)" class="separator" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DropdownOption, DropdownOptionsTypes } from '@/lib/types/dropdown';

const props = defineProps<{
  value?: DropdownOption;
  placeholder?: string;
  options: DropdownOption[];
  icon?: string;
  isText?: boolean;
}>();

const emit = defineEmits<(e: 'update:value', value: DropdownOption) => void>();

const currentOption = ref(props.value);
const isMenuOpen = ref(false);

const optionsExcluded = computed(() =>
  props.options.filter(option => option.value !== currentOption.value?.value)
);

const optionsTypes = ref<DropdownOptionsTypes[]>([]);

watch(
  optionsExcluded,
  () => {
    if (optionsExcluded.value.some(o => o.type !== undefined))
      optionsExcluded.value.forEach(option => {
        const addIndex = optionsTypes.value.findIndex(o => o.type === option.type);
        if (addIndex === -1) {
          optionsTypes.value.push({ type: option.type ?? '', options: [option] });
        } else if (!optionsTypes.value[addIndex]?.options.some(o => o.value === option.value)) {
          optionsTypes.value[addIndex]?.options.push(option);
        }
      });
  },
  { immediate: true }
);

watch(
  () => props.value,
  newValue => {
    currentOption.value = newValue;
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/animation.scss';
@use '$/border-radius.scss';
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/shadows.scss';
@use '$/button.scss';

.placeholder {
  font-size: 20px;
  font-weight: 400;
  color: colors.$grey-900;

  @media (width <= 768px) {
    margin: 0;
    font-size: 16px;
  }
}

.drop-down {
  font-size: var(--d-font-size);

  .drop-down-button {
    @include button.outlined(black, $size: var(--d-size), $color-hover: none);
    @include button.with-icon();

    position: relative;

    &:hover {
      background-color: white !important;
    }

    &.no-text {
      @include button.transparent(small);
      @include button.with-icon();

      border-color: transparent;
    }

    .dropdown-title {
      gap: 5px;

      .semi-bold {
        font-weight: 600;
      }
    }
  }

  .drop-down-menu {
    position: absolute;
    z-index: 5;

    overflow-y: auto;
    flex-direction: column;

    min-width: 200px;
    max-height: 300px;
    margin-top: 55px;

    background-color: white;
    border-radius: border-radius.$default;
    box-shadow: shadows.$page;

    .option-type-wrapper {
      flex-direction: column;

      .options {
        flex-direction: column;
      }
    }
  }

  .drop-down-item {
    $border-radius: border-radius.$default;

    @include mouse-behavior.clickable-alpha(background-color, colors.$grey-900);

    cursor: pointer;

    flex-direction: column;
    gap: 5px;

    padding: 16px;

    background-color: white;

    &:last-child {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .type {
      font-weight: 600;
    }
  }

  .separator {
    align-self: center;
    width: 100%;
    height: 1px;
    background-color: colors.$grey-500;
  }
}
</style>
